import React, { useState, useEffect,useRef } from 'react';
import Airtable from 'airtable';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import MarkerClusterGroup from 'react-leaflet-cluster'
import { v4 as uuidv4 } from 'uuid';
import L, { Icon } from 'leaflet';
import './App.css';
import DetailsWindow from './components/DetailsWindow';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import LoadingSpinner from './components/LoadingSpinner';
import Select from 'react-select';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import info from "../src/assets/info.svg"
import plus from '../src/assets/plus-circle.svg'

// import { Modal, Button } from 'react-bootstrap';
// import MultiStepForm from './components/MultiStepForm';


const RedCheckbox = styled(Checkbox)({
  '&.Mui-checked': {
    color: 'red',
  },
});



const App = () => {

  const [isWindowVisible, setIsWindowVisible] = useState(false);
  const [windowContent, setWindowContent] = useState("");
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const [selectedPoi, setSelectedPoi] = useState(null);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [loco,setLoco] = useState(null);
  const [prevZoom, setPrevZoom] = useState(null);
  const [prevCenter, setPrevCenter] = useState(null);
  const [selectedPoiIndex, setSelectedPoiIndex] = useState(null);
  const [showAllFilters, setShowAllFilters] = React.useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterBoxClass, setFilterBoxClass] = useState('');
  const [modalClass, setModalClass] = useState('');
  const [loading, setLoading] = useState(true);
  const [Findata, setFinData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1300);
  const [showModal, setShowModal] = useState(false);
  const [zoom, setZoom] = useState(6.2);
  const [center, setCenter] = useState([46.681067, 2.447557]);

  const [map, setMap] = useState(null);

  useEffect(() => {
    // Define the global gtag function
    window.dataLayer = window.dataLayer || [];
    window.gtag = function() {
      window.dataLayer.push(arguments);
    }
  
    // Add the Google Analytics script dynamically
    const gAnalyticsScript = document.createElement('script');
    gAnalyticsScript.async = true;
    gAnalyticsScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-Y5CCN92L4K';
    document.head.appendChild(gAnalyticsScript);
  
    // Initialize the Google Analytics
    window.gtag('js', new Date());
    window.gtag('config', 'G-Y5CCN92L4K');
  
    // Additional event tracking can be added here
  
  }, []);
  



  useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 1300);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
}, []);

  

  useEffect(() => {
    if (showFilterBox) {
      setFilterBoxClass('fade-in-slide-up-entered');
    } else {
      setFilterBoxClass('fade-in-slide-up-exiting');
      setTimeout(() => {
        setFilterBoxClass('');
      }, 300); // Match this with your transition duration
    }
  }, [showFilterBox]);

  useEffect(() => {
    if (isModalOpen) {
      setModalClass('fade-in-slide-up-entering');
      setTimeout(() => {
        setModalClass('fade-in-slide-up-entered');
      }, 0);
    } else {
      setModalClass('fade-in-slide-up-exiting');
      setTimeout(() => {
        setModalClass('fade-in-slide-up-exited');
      }, 100);  // Match this with your transition duration
    }
  }, [isModalOpen]);


  const handleMarkerClick = (poi,latLng) => {
    setSelectedPoi(poi);
    setIsPaneOpen(true);
    setNav(false);
    setLoco(latLng);
    setShouldOpenPane(true);
    setZoom(map.getZoom());
    setPrevCenter(map.getCenter());
    setCenter(latLng);
    // setZoom(9);

    
    console.log("Ismobile: ",isMobile,window.innerWidth)

    const newURL = `${window.location.origin}${window.location.pathname}?poi=${poi.Name}`;
    window.history.pushState({}, '', newURL);
};

const [shouldOpenPane, setShouldOpenPane] = useState(false);


useEffect(() => {

  try {
    console.log("Shared link: ",shouldOpenPane)
    if (shouldOpenPane === true) return;
  // Extract query parameters from the current URL
  const urlParams = new URLSearchParams(window.location.search);
  const poiName = urlParams.get('poi');
  console.log("We be trying to work.",poiName,filteredData?.length)
  if (poiName && filteredData?.length > 0) {
    console.log("We be working.")
    const decodedName = (poiName); // Decode the name
    // Find the POI by name from your data and open its details
    const poiToOpen = filteredData?.find((poi) => poi.fields.Name === decodedName);
    if (poiToOpen) {
      handleMarkerClick(poiToOpen.fields, poiToOpen.latLng); // Assume you have a handleMarkerClick function
    }
  }
  setShouldOpenPane(false);
}
catch {

}
},[ Findata,shouldOpenPane]); // Only run when filteredData changes




const clearFilters = () => {
  setFilters({ type: [], region: [], Monderural: [], Activité: [], search: '' });
  setFilteredData(Findata);
};



  
  const [filters, setFilters] = useState({
    type: [],
    region: [],
    Monderural: [],
    Activité: [],
    search: ''
  });
  const [uniqueTypes, setUniqueTypes] = useState([]);
  const [uniqueRegions, setUniqueRegions] = useState([]);
  const [uniqueMonderurals, setUniqueMonderurals] = useState([]);
  const [uniqueActivités, setUniqueActivités] = useState([]);
  const [isNavOpen,setNav] = useState(true);

  const [selectedregion, setselectedRegion] = useState(null);
  const [type, setType] = useState(null);
  const [monderural, setMonderural] = useState(null);
  const [activite, setActivite] = useState(null);

  // const [typeOptions, setTypeOptions] = useState([]);
  const [reigionOptions, setReigionOptions] = useState([]);
  const [MondeOptions, setMondeOptions] = useState([]);
  const [activieOptions, setActivieOptions] = useState([]);
  const [expandedFilter, setExpandedFilter] = useState("");

  // use this state to hold the filter count
const [filterCount, setFilterCount] = useState(0);

// Create a ref for the filter box
const filterBoxRef = useRef();

// function to count the filters
const countFilters = () => {
  // count your filters here based on your filters state or other logic.
  // As an example, I am using the number of regions in the filters
  setFilterCount(filters.region.length+filters.Activité.length+filters.type.length+filters.Monderural.length);
}

// Add a useEffect hook to listen for filter changes
useEffect(() => {
  countFilters();
}, [filters]); // update when filters change

useEffect(() => {
  const handleClickOutside = (event) => {
    // If the click is outside the filter box, hide the filter box
    if (filterBoxRef.current && !filterBoxRef.current.contains(event.target)) {
      setShowFilterBox(false);
    }
  };
  
  // Add the event listener when the component mounts
  document.addEventListener('mousedown', handleClickOutside);
  
  // Remove the event listener when the component unmounts
  return () => document.removeEventListener('mousedown', handleClickOutside);
}, []);


  const handleFilterChange = (e, filterValue) => {
    e.stopPropagation(); // Prevent event from bubbling up to parent elements
    const { name } = e.target;
  
    if (name === "search") {
      // Handle search filter separately as it is a string
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: filterValue,
      }));
    } else {
      // Handle other filters as lists
      setFilters((prevFilters) => {
        // Check if filter already exists
        if (prevFilters[name].includes(filterValue)) {
          // Filter exists, remove it
          return {
            ...prevFilters,
            [name]: prevFilters[name].filter((value) => value !== filterValue),
          };
        } else {
          // Filter doesn't exist, add it
          return {
            ...prevFilters,
            [name]: [...prevFilters[name], filterValue],
          };
        }
      });
    }
  
    console.log("Filters are as following: ", filters);
  };
  

  
  
  
  const customIcon = new L.Icon({
    iconUrl: 'https://i.postimg.cc/VLVhvxg8/image.png',
    iconSize: [35, 35], // Size of the icon, in pixels
    iconAnchor: [12.5, 41], // The point of the icon which will correspond to marker's location
    popupAnchor: [0, -41], // The point from which the popup will "open", relative to the iconAnchor
  });






  
const applyFilters = (filters, data) => {

  console.log("Search is: ",filters.search)
  return data.filter((poi) => {
    const { Type: type, Pays: region, 'Monde rural ?': monderural, Activité: activité ,Name: poiName} = poi.fields;
    let matchesType = true;
    let matchesRegion = true;
    let matchesMonderural = true;
    let matchesActivité = true;
    let matchesSearch = true;

    if (filters.type && filters.type.length > 0) {
      matchesType = Array.isArray(type) 
        ? type.some(t => filters.type.includes(t)) 
        : filters.type.includes(type);
    }

    if (filters.region && filters.region.length > 0) {
      matchesRegion = filters.region.includes(region);
    }

    if (filters.Monderural && filters.Monderural.length > 0) {
      matchesMonderural = filters.Monderural.includes(monderural);
    }

    if (filters.Activité && filters.Activité.length > 0) {
      matchesActivité = filters.Activité.includes(activité);
    }

    if (filters.search && filters.search.length > 0) {
      const searchTerm = filters.search;
      matchesSearch = (poiName && poiName.toLowerCase().includes(searchTerm.toLowerCase()))
        || (Array.isArray(type) && type.some((t) => t.toLowerCase().includes(searchTerm.toLowerCase())))
        || (region && region.toLowerCase().includes(searchTerm.toLowerCase()))
        || (monderural && monderural.toLowerCase().includes(searchTerm.toLowerCase()))
        || (activité && activité.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    return matchesType && matchesRegion && matchesMonderural && matchesActivité && matchesSearch;
  });
};

useEffect(() => {
  const newFilteredData = applyFilters(filters, Findata);
  setFilteredData(newFilteredData);
}, [filters]);
  
  
  const extractUniqueFilterValues = (data) => {
    const typeSet = new Set();
    const regionSet = new Set();
    const MonderuralSet = new Set();
    const ActivitéSet = new Set();

  
    data.forEach((record) => {
      const { Type: type, Pays: region, 'Monde rural ?': monderural, Activité: activité } = record.fields;
  
      if (type && Array.isArray(type)) {
        type.forEach((t) => typeSet.add(t));
      } else if (type) {
        typeSet.add(type);
      }
  
      if (region) regionSet.add(region);
      if (monderural) MonderuralSet.add(monderural);
      if (activité) ActivitéSet.add(activité);
    });
  
    return {
      type: Array.from(typeSet),
      region: Array.from(regionSet),
      Monderural: Array.from(MonderuralSet),
      Activité: Array.from(ActivitéSet),
    };
  };
  
  


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const API_URL = 'https://api.airtable.com/v0/appnJMqtPzTD87XaN/tblAk6pFgdKHUHXKs';
        const API_KEY = 'Bearer patxqLfywzW9jAEWz.e01c32b42f6bdc9796f7d7dd98a7eb340ef90bdefac02c44586ef9dc1c23c402';
        let allRecords = [];
      let offset;

      do {
        let url = API_URL;
        if (offset) url += `?offset=${offset}`;

        const response = await fetch(url, {
          headers: {
            'Authorization': API_KEY,
          },
        });

        const data = await response.json();
        allRecords = allRecords.concat(data.records);
        offset = data.offset;
      } while (offset);

      // Now, the allRecords variable contains all the records.
      const airtableData = { records: allRecords };
        console.log ("Airtable Data is: ",airtableData.records)
        const uniqueFilterValues = extractUniqueFilterValues(airtableData.records);
        
        setUniqueTypes(uniqueFilterValues.type);
        setUniqueRegions(uniqueFilterValues.region);
        setUniqueMonderurals(uniqueFilterValues.Monderural);
        setUniqueActivités(uniqueFilterValues.Activité);

        const dataWithLatLng = airtableData.records.map(record => {
          const coordonnees = record.fields['Coordonnées GPS'];
          let latLng = null;
          if (coordonnees) {
            const [lat, lng] = coordonnees.split(', ').map(Number); // split by ', ' and convert strings to Numbers
            latLng = {
              id: record.id || "dummy-id", // use actual id from record, or fallback to a dummy id
              name: record.fields.Name || "No Name",
              lat: lat,
              lng: lng
            };
          }
          return { ...record, latLng };
        });
       console.log ("positional data is ",dataWithLatLng)

        
        setFinData(dataWithLatLng);
        setFilteredData(dataWithLatLng);
        
  
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      finally {
        setLoading(false); // Add this line to indicate that the data fetching has finished
      }
    };
    fetchData();
  
  }, []);


const handleDivClick = (e) => {
  if (e.target.tagName.toLowerCase() !== 'input') {
    setExpandedFilter((current) => (current === 'region' ? '' : 'region'));
  }
  };

const handleDivClickType = (e) => {
  if (e.target.tagName.toLowerCase() !== 'input') {
    setExpandedFilter((current) => (current === 'type' ? '' : 'type'));
  }
  };
  const handleDivClickMonde = (e) => {
    if (e.target.tagName.toLowerCase() !== 'input') {
      setExpandedFilter((current) => (current === 'Monderural' ? '' : 'Monderural'));
    }
    };
  
  const handleDivClickActivit = (e) => {
    if (e.target.tagName.toLowerCase() !== 'input') {
      setExpandedFilter((current) => (current === 'Activité' ? '' : 'Activité'));
    }
    };

  return (
    <div>
      {loading ? (
        <div className="spinner-container">
        <LoadingSpinner />
      </div>
      ) : (
          <>
          {!isPaneOpen && (<a  href="https://www.ecclesialab.org" target="_blank" rel="noopener noreferrer"  style={{ position: 'absolute', left: '10px', top: '10px', zIndex: '9999',border:'0px',backgroundColor:'transparent' }}>
                {/* Add your logo here */}
                <img src="https://i.postimg.cc/6pmHJbVg/image.png" alt="Logo" style={{ height: '50px', marginLeft: '10px' }} />
              </a>)}
           
          {!(isPaneOpen && isMobile) &&(<nav className={`navbar ${isMobile ? 'navbar-open' : (isNavOpen ? 'navbar-open' : 'navbar-closed')}`} style={{ 
              position: 'absolute', 
              zIndex: 1, 
              width: '90%', 
              top: 0,
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
            }}>

              

              
            <div className='search-container' >
              <div className='search'>
                <button onClick={() => setShowFilterBox(!showFilterBox)}  style={{position:'absolute',marginLeft:'3px',marginTop:'3px',left:0,width:'50px'}}>
                  <img src='https://i.postimg.cc/dVhSRx2r/icons8-filter-60.png' width={23} ></img>
                  {filterCount > 0 && (
          <span style={{ fontSize:'0.6rem',position: 'absolute', top: 0, left: 0, backgroundColor: 'red', color: 'white', borderRadius: '50%', padding: '2px 5px'}}>
            {filterCount}
          </span>
        )}
                </button>
                <input
                  type="text"
                  name="search"
                  placeholder="Que cherchez-vous ?"
                  value={filters.search}
                  onChange={(e) => handleFilterChange(e, e.target.value)}
                />
                <button  style={{right:0,position:'absolute',marginTop:'2px',width:'50px'}}>
                  <img src='https://i.postimg.cc/kgycXQtJ/icons8-search-100.png' width={25} style={{maxWidth:'25'}} ></img>
                </button>
              </div>
              
              {showFilterBox && 
                <div className={`filters ${filterBoxClass}`}  ref={filterBoxRef}>
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <h1 style={{fontWeight:'500',fontSize:'1.6rem'}}>Filtre </h1>
                    {filterCount >0 && <button style={{color:'red',fontSize:'1rem'}} onClick={clearFilters}>Clear Filters</button>}
                  </div>
                  <div style={{marginTop:'-20px'}}>
                  <div onClick={handleDivClick}>
                    <span className={expandedFilter === "region" ? "arrow down" : "arrow right"}></span>
                      Region
                      {expandedFilter === "region" && uniqueRegions.map(region => (
                        <div key={region}>

                            <RedCheckbox
                                name="region"
                                checked={filters.region.includes(region)}
                                onChange={(e) => handleFilterChange(e, region)}
                              />
                          <label>{region}</label>
                        </div>
                      ))}
                  </div>
                  
                  <div onClick={handleDivClickType} style={{maxHeight: '200px', overflowY: 'auto'}}>
                      <span className={expandedFilter === "type" ? "arrow down" : "arrow right"}></span>
                      Type
                      {expandedFilter === "type" && (
                        <>
                          {uniqueTypes.slice(0, showAllFilters ? uniqueTypes.length : 3).map(type => (
                            <div key={type}>
                              <RedCheckbox
                                name="type"
                                checked={filters.type.includes(type)}
                                onChange={(e) => handleFilterChange(e, type)}
                              />
                              <label>{type}</label>
                            </div>
                          ))}
                          {uniqueTypes.length > 3 && (
                            <button
                              style={{color:'red',fontSize:'0.9rem'}}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent the click event from bubbling up to the parent div
                                setShowAllFilters(!showAllFilters);
                              }}
                            >
                              {showAllFilters ? "View Less" : "View More"}
                            </button>
                          )}
                        </>
                      )}
                    </div>


                <div onClick={handleDivClickMonde}>
                    <span className={expandedFilter === "region" ? "arrow down" : "arrow right"}></span>

                    Monde rural
                      {expandedFilter === "Monderural" && uniqueMonderurals.map(monderural => (
                        <div key={monderural}>
                          <RedCheckbox
                              // type="checkbox"
                              name="Monderural"  // Add this line
                              checked={filters.Monderural.includes(monderural)}
                              onChange={(e) => handleFilterChange(e, monderural)}
                            />
                          <label>{monderural}</label>
                        </div>
                      ))}
                  </div>

                <div onClick={handleDivClickActivit}>
                    <span className={expandedFilter === "region" ? "arrow down" : "arrow right"}></span>
                    Activité
                      {expandedFilter === "Activité" && uniqueActivités.map(activite => (
                        <div key={activite}>
                         <RedCheckbox
                              // type="checkbox"
                              name="Activité"  // Add this line
                              checked={filters.Activité.includes(activite)}
                              onChange={(e) => handleFilterChange(e, activite)}
                            />
                          <label>{activite}</label>
                        </div>
                      ))}
                  </div>

            </div>
                </div>
              }
            </div>
          </nav>)}
          {!isPaneOpen && (<button className='info_button' onClick={() => setIsModalOpen(true)} style={{cursor:'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: '9999',border:'0px',backgroundColor:'transparent' }}>
      <img src={info} alt="Open Modal" width={50} />
    </button>)}

    {!isPaneOpen && (<a href='https://airtable.com/appnJMqtPzTD87XaN/shrOl2EwVpp4PZWV4' target="_blank" rel="noopener noreferrer" className='info_button'  style={{cursor:'pointer', position: 'absolute', left: '10px', bottom: '10px', zIndex: '9999',border:'0px',backgroundColor:'transparent' }}>
      <img src={plus} alt="Open Modal" width={50} />
    </a>)}

    {isModalOpen && (
  <div onClick={() => setIsModalOpen(false)} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: '9999' }}>
  <div className={modalClass} onClick={(e) => e.stopPropagation()} style={{ position: 'relative', margin: '10% auto', width: isMobile?'80%':"30%", backgroundColor: '#fff', padding: '20px', border:'0px',borderRadius:'22px' }}>
      <button onClick={() => setIsModalOpen(false)} style={{ position: 'absolute', left: '10px', top: '10px',border:'0px',backgroundColor:'transparent' }}>
          <img src={info} alt="Close Modal" width={35} />
      </button>
      <h1 style={{ textAlign: 'center', color: '#f9647e', fontSize: '1.5em' }}>Information</h1>

      <hr style={{ borderColor: '#f9647e', width: '80%', margin: '0 auto' }} />
      <div style={{padding: '0 20px'}}>
          <p style={{color:'#8d8d8d'}}>Cette cartographie a été conçue par l’EcclesiaLab, laboratoire de recherche en théologie rattaché à l’UCLouvain (Belgique). Les deux pieds dans le monde universitaire et ecclésial, nous travaillons sur l’innovation dans l’Eglise, en Occident francophone (France, Belgique, Suisse, Luxembourg et Québec). </p>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src='https://i.postimg.cc/kX6VhmvN/magnifying-glass-plus.png' width={20}/> 
              <a href='https://www.ecclesialab.org' target="_blank" rel="noopener noreferrer" style={{color:'#8d8d8d',fontWeight:'bold', paddingLeft: '10px'}}>En savoir plus</a>
          </div>

          <p style={{color:'#8d8d8d'}}>Nous avons rassemblé ici des lieux chrétiens innovants, dans le but de vous les faire découvrir, de vous inspirer et de faciliter la rencontre. L’équipe de l’EcclesiaLab a récolté les 200 premiers lieux, à vous de jouer maintenant !</p>
      </div>
  </div>
</div>

)}

    <div className='container' style={{ position: 'relative', zIndex: 0 }}>
      <MapContainer className="lower-z-index" key={isPaneOpen ? "open-state": "closed-state"} 
      center={center}
      zoom={zoom}
      // whenCreated={handleMapCreated}
      ref={setMap}
      zoomControl ={false} 
      
      style={{ 
      width: isPaneOpen ? 'calc(100vw - 130vh)' : '100vw', 
      height: '100vh' ,
      
    }}>

      <TileLayer
          url="https://{s}.tile.jawg.io/jawg-sunny/{z}/{x}/{y}{r}.png?access-token=PRjNGSEFreZIQnED5MQhvjyo2rCWBleGmAy2gHgL6cx2zlJl0TjXop6o4fAk0Gfh"
          attribution='&copy; <a href="https://www.jawg.io" target="_blank">Jawg</a> contributors'
          minZoom={0}
          maxZoom={22}
          subdomains="abcd"
          accessToken="PRjNGSEFreZIQnED5MQhvjyo2rCWBleGmAy2gHgL6cx2zlJl0TjXop6o4fAk0Gfh"
        />
       <MarkerClusterGroup
  chunkedLoading
  iconCreateFunction={(cluster) => {
    return L.divIcon({ 
      html: '<b>' + cluster.getChildCount() + '</b>',
      className: 'my-cluster-icon', 
      iconSize: L.point(30, 30) 
    });
  }}
>
  {filteredData
    .filter((poi) => poi.latLng && poi.latLng.lat !== undefined && poi.latLng.lng !== undefined)
    .map((poi) => (
      <Marker
        key={poi.latLng.id}
        position={[poi.latLng.lat, poi.latLng.lng]}
        icon={customIcon}
        eventHandlers={{ click: () => handleMarkerClick(poi.fields,poi.latLng) }}
      ></Marker>
  ))}
</MarkerClusterGroup>
   {/* {loco && ( <UpdateView loco={loco} prevZoom={prevZoom} prevCenter={prevCenter} />)} */}
      </MapContainer>
      <SlidingPane
      className="custom-sliding-pane"
      isOpen={isPaneOpen}
      from="right"
      width="130vh"
      onRequestClose={() => {
        setIsPaneOpen(false);
        setNav(true);
        // setZoom(prevZoom); 
        setCenter(prevCenter);
      }}
      hideHeader
    >
      {selectedPoi && <DetailsWindow setIsPaneOpen={setIsPaneOpen} poi={selectedPoi} coordinates={loco} />}
    </SlidingPane>
    </div>
          </>
      )}

    </div>
  );
};

export default App;
