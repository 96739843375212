import {React, useState, useEffect} from 'react';
import styles from './DetailsWindow.module.css';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L, { Icon } from 'leaflet';
import facebooklogo from '../assets/facebooklogo.svg'
import insta from "../assets/instagram-logo.svg"
import emailjs from 'emailjs-com';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import share from "../assets/share.svg"
import close from "../assets/x-circle.svg"



const customIcon = new L.Icon({
  iconUrl: 'https://i.postimg.cc/VLVhvxg8/image.png',
  iconSize: [40, 40], // Size of the icon, in pixels
  iconAnchor: [12.5, 41], // The point of the icon which will correspond to marker's location
  popupAnchor: [0, -41], // The point from which the popup will "open", relative to the iconAnchor
});
const DetailsWindow = ({  setIsPaneOpen ,poi, coordinates }) => {
  const [width, setWidth] = useState('130vh');
  const [isMobile,setIsmobile] =useState(false) 

  useEffect(() => {
    const updateWidth = () => {
        if (window.innerWidth < 768) { // 768px is a commonly used breakpoint for mobile devices
            setWidth('20vh'); // or whatever width you want for mobile screens
            setIsmobile(true);
        } else {
            setWidth('130vh');
            setIsmobile(false);
        }
    };
    
    updateWidth(); // Update width on component mount
    
    window.addEventListener('resize', updateWidth); // Update width on window resize
    
    return () => window.removeEventListener('resize', updateWidth); // Cleanup event listener on component unmount
}, []);

  const [showModal, setShowModal] = useState(false);
  const [comment, setComment] = useState('');
    console.log(poi)
  if (!poi) {
    return <p>Loading data...</p>;
  }
  

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const handleShareClick = () => {
    // Generate the URL you want to share
    
    const urlToShare = `${window.location.origin}${window.location.pathname}?poi=${poi.Name}`;
  
    // Copy to clipboard
    navigator.clipboard.writeText(urlToShare)
      .then(() => {
        // Show toast on successful copy
        toast.success('Copié dans le presse-papier!');
      })
      .catch((err) => {
        console.error('Could not copy text: ', err);
        // Show toast on failure
        toast.error('Failed to copy text!');
      });
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero indexed in JavaScript
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
}

function getIcon(type) {
  if (type.includes('Article')) {
    return 'https://i.postimg.cc/cJgRjBJx/article-ny-times-1.png';
  } else if (type.includes('Vidéo')) {
    return 'https://i.postimg.cc/7ZybD1XP/video-1.png';
  } else if (type.includes('podcast')) {
    return 'https://i.postimg.cc/Cx1LsFhD/headphones-1.png';
  } else if (type.includes('Documentation')) {
    return 'https://i.postimg.cc/zBNspjCM/link-1.png';
  }
  return null;
}
function renderDiv(docType, docTitre, docLien) {
  if (!docType || !docTitre || !docLien) return null;
  const iconUrl = getIcon(docType);
  if (!iconUrl || !docLien) return null;

  return (
      <tr >
          <td style={{ verticalAlign: 'middle' ,textAlign: 'center', width: '50px' }}>
              <img  width={25} src={iconUrl} />
          </td>
          <td style={{ verticalAlign: 'middle',textAlign:'left' }}>
              <a href={docLien} target="_blank" rel="noopener noreferrer">{docTitre}</a>
          </td>
      </tr>
  );
}


  

  const handleCommentSubmit = () => {
    // Prepare email data
    const emailData = {
      name: poi.Name, // POI Name
      message: comment, // User comment
      // You can add more data fields here if needed
    };
    console.log("email: ",emailData)

    // Send the email
    emailjs.send(
      'service_r6qa2f7', // replace with your service ID
      'template_tjljfip', // replace with your template ID
      emailData,
      'kGfczhB-AVv8VPZtK' // replace with your user ID
    )
    .then((response) => {
      console.log('Email sent successfully', response.status, response.text);
      // Close the modal and reset the comment
      toggleModal();
      setComment('');
    }, (error) => {
      console.log('Email sending failed', error);
    });
  };

  const {
    "Site internet": siteInternet,
    "Date de création": dateDeCreation,
    Diocèse,
    Pays,
    Réseau,
    "Adresse pour la carte": adressePourLaCarte,
    "Doc 1 - Ref EcL": doc1RefEcL,
    Type,
    Contact,
    "Kairos / porte d'entrée": kairosPorteEntree,
    "Doc 1 - fichier": doc1Fichier,
    "Photo de présentation": photoDePresentation,
    "Ref photo de présentation": photoref,
    Name,
    Référence,
    "Doc 1 - Titre": doc1Titre,
    "Doc 2 - Titre": doc2Titre,
    "Doc 3 - Titre": doc3Titre,
    "Doc 4 - Titre": doc4Titre,
    Activité,
    Initiative,
    "Monde rural ?": mondeRural,
    "Doc 1 - lien": doc1Lien,
    "Doc 2 - lien": doc2Lien,
    "Doc 3 - lien": doc3Lien,
    "Doc 4 - lien": doc4Lien,
    Adresse,
    "Qui l'a fourni": quiLaFourni,
    "Start date": startDate,
    "Confession": confession,
    "Descriptif":Descriptif,
    "Facebook":Facebook,
    "Instagram":Instagram,
    "Doc 1 - Type":doc1type,
    "Doc 2 - Type":doc2type,
    "Doc 3 - Type":doc3type,
    "Doc 4 - Type":doc4type,
    
  } = poi;

  const location = [coordinates.lat, coordinates.lng];
  console.log("LOCATIONNN: ",location)
  return (
    <div style={{marginTop:-20}} className={styles.maindiv}>
      {isMobile && (<img 
        src={close}
                style={{
                    position: 'fixed',
                    top: '10px',
                    left: '10px',
                    zIndex: '10001'  // Ensure it's on top of other elements
                }}
                onClick={() => setIsPaneOpen(false)}
            >
                
            </img>)}
    
    <div   className="map-wrapper">
    <div className="inner-map-wrapper">
    <MapContainer center={location} attributionControl={false} zoomControl={false} zoom={13} style={{ width:'100%',height: "200px",marginTop:'0px',zIndex:'9999'}}>
      <TileLayer
          url="https://{s}.tile.jawg.io/jawg-sunny/{z}/{x}/{y}{r}.png?access-token=PRjNGSEFreZIQnED5MQhvjyo2rCWBleGmAy2gHgL6cx2zlJl0TjXop6o4fAk0Gfh"
          attribution='&copy; <a href="https://www.jawg.io" target="_blank">Jawg</a> contributors'
          minZoom={0}
          maxZoom={22}
          subdomains="abcd"
          accessToken="PRjNGSEFreZIQnED5MQhvjyo2rCWBleGmAy2gHgL6cx2zlJl0TjXop6o4fAk0Gfh"
        />
        <Marker
        position={location}
        icon={customIcon}
      ></Marker>
      </MapContainer>
      <div className={styles.mapFadeOverlay}></div>
      </div>
      </div>
    <div className={styles.detailsWindow}>
      
      {Name &&(<div className={styles.titdiv}>
        <p className={styles.title}>{Name}</p>
      </div>)}
      
      {Adresse && (<p className={styles.address}><img src='https://i.postimg.cc/ryggPfbY/icons8-location-50.png' width={15} /> {Adresse}</p>)}
      {Type && (<div>
        <p className={styles.nname}>Type</p>
            <p style={{marginTop:"-1rem"}}>{Type?.join(', ')}</p>
      </div>)}
      {kairosPorteEntree && (<div style={{marginTop:'-0.7rem'}}>
            <p className={styles.nname}>Thématique</p>
            <p style={{marginTop:"-1rem"}}>{kairosPorteEntree?.join(', ')}</p>
          </div>)}
     {Descriptif && (<> <p style={{fontSize:'25px',fontWeight:'600',color:'grey'}}>Description</p> 
      <p> {Descriptif
      }</p>
</>)}
        
<div className={styles.links}>
            <table style={{ margin: '0 auto' }}>
                <tbody>
                    {renderDiv(doc1type, doc1Titre, doc1Lien)}
                    {renderDiv(doc2type, doc2Titre, doc2Lien)}
                    {renderDiv(doc3type, doc3Titre, doc3Lien)}
                    {renderDiv(doc4type, doc4Titre, doc4Lien)}
                </tbody>
            </table>
        </div>
          <hr style={{marginTop:'3%',marginBottom:'3%'}}></hr>
        
      {/* <h3 className={styles.information}>Information</h3> */}
      <div className={styles.flexContainer}>
  {photoDePresentation && (
    <div className={styles.flexItem}>
      <img
        src={photoDePresentation[0].thumbnails.large.url}
        alt={Name}
        className={styles.image}
        style={{ borderRadius: '22px', maxWidth: '80%', width: '80%', height: 'auto' }}
      />
    </div>
  )}

  <div className={styles.flexItem}>
    <div style={{ whiteSpace: 'nowrap', fontSize: '25px', fontWeight: '600', color: 'grey' }}>
      Informations
    </div>
    {Réseau && (
      <>
        <div style={{ marginTop: '2%' }} className={styles.nname}>
          Réseau
        </div>
        <div>{Réseau?.join(', ')}</div>
      </>
    )}
    {Diocèse && (
      <>
        <div style={{ marginTop: '5%' }} className={styles.nname}>
          Diocèse
        </div>
        <div>
          {Diocèse?.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      </>
    )}
    {confession && (
      <>
        <div style={{ marginTop: '5%' }} className={styles.nname}>
          Confession
        </div>
        <div>
          {confession}
        </div>
      </>
    )}
  </div>

  <div className={styles.flexItem}>
    <div style={{ fontSize: '25px', fontWeight: '600', color: 'grey' }}>Histoire</div>
    {startDate && (
      <>
        <div style={{ marginTop: '2%' }} className={styles.nname}>
          Créé le
        </div>
        <div>{formatDate(startDate)}</div>
      </>
    )}
    {Initiative && (
      <>
        <div style={{ marginTop: '5%' }} className={styles.nname}>
          Initiative
        </div>
        <div>
          {Initiative?.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      </>
    )}
  </div>
</div>

<hr style={{marginTop:'3%',marginBottom:'3%'}}></hr>
<div style={{ flexDirection: isMobile ? 'column' : 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
  {/* Contact Heading */}
  <div style={{ fontSize: '20px', fontWeight: '600', color: 'grey', marginRight: '20px' }}>
    Contact
  </div>

  {/* Contact */}
  {Contact && (
    <div style={{ fontSize: '15px', color: 'darkgray', marginRight: '20px', flex: 1 }}>
      <a href={`mailto:${Contact}`} target="_blank" rel="noopener noreferrer">
        {/* {Contact.length > 18 ? `${Contact.substring(0, 22)}...` : Contact} */}
        Adresse Mail
      </a>
    </div>
  )}

  {/* Site Internet */}
  {siteInternet && (
    <div style={{ fontSize: '15px', color: 'blue', marginRight: '20px', flex: 1 }}>
      <a href={siteInternet} target="_blank" rel="noopener noreferrer">
        {/* {siteInternet.length > 18 ? `${siteInternet.substring(0, 22)}...` : siteInternet} */}
        Site web
      </a>
    </div>
  )}

  {/* Icons */}

  {/* Icons */}
  <div style={{ flexDirection: isMobile ? 'column' : 'row',marginBottom: '0%', display: 'flex', alignItems: 'center', flex: (Contact || siteInternet) ? 1 : 0 }}>
    {Facebook && (<a href={Facebook} target="_blank" rel="noopener noreferrer"  style={{ marginRight: '10px', cursor: 'pointer' }}>
      {/* Your first icon here */}
      <img src={facebooklogo} alt="Facebook Logo" width="25" height="25" />
    </a>)}

    {Instagram && (<a href={Instagram} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px', cursor: 'pointer' }}>
      {/* Your second icon here */}
      <img src={insta} alt="Instagram Logo" width="25" height="25" />
    </a>)}
    
    <div onClick={handleShareClick}  style={{ marginRight: '10px', cursor: 'pointer' }}>
      {/* Your second icon here */}
      <img src={share} width="25" height="25"></img>
    </div>
    <ToastContainer style={{zIndex:'100001'}} position="top-right" />
    <div style={{ cursor: 'pointer',marginLeft:'16px' }} onClick={toggleModal}>
      {/* Your third icon here */}
      <img src={require('../assets/modifier.png')} alt="Third Icon" width="110" height="auto" style={{ right: 0 ,marginTop:'5px'}} />
    </div>
  </div>
</div>



      
      {/* <p style={{width:'40%',marginLeft:'50%',fontSize:'0.9rem'}}>{photoref}</p> */}
      
    </div>
    {showModal && (
  <div className={styles.modalOverlay }>
    <div className={styles.modal}>
      <h2 className={styles.modalTitle}>{Name}</h2>
      <textarea
        placeholder="Suggérez des modifications ici ..."
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        className={styles.commentBox}
      ></textarea>
      <button className={styles.modalButton + ' ' + styles.submitButton} onClick={handleCommentSubmit}>Envoyer</button>
      <button className={styles.modalButton + ' ' + styles.cancelButton} onClick={toggleModal}>Fermer</button>
    </div>
  </div>
)}

    </div>
  );
};

export default DetailsWindow;
